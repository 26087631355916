import { DIMENSIONS, Ratio } from '@canalplus/mycanal-commons';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { REFRESH_PROGRESS_BAR_MS } from '../../constants/limits';
import { isOngoing } from '../../helpers/card/card-helper';
import { getHodorSticker } from '../../helpers/stickers/stickers-helper';
import { featUpcomingHodorStickerSelector } from '../../store/slices/application-selectors';
import type { ContentStrateV5 } from '../../templates/LandingV5/data/formatter';
import CardLogoChannel from '../CardContents/CardLogoChannel/CardLogoChannel';
import CoverImage from '../CoverImage/CoverImage';
import ProgressBar from '../ProgressBar/ProgressBar';
import Sticker from '../Sticker/Sticker';
import styles from './CarrouselTemplate.css';

export type CarrouselTemplateItemProps = {
  borderRadius?: boolean;
  content: ContentStrateV5;
  isDesktopOnly?: boolean;
  isMobileOnly?: boolean;
  isTvDevice?: boolean;
  ratio: Ratio;
};

function CarrouselTemplateItem({
  borderRadius = false,
  content,
  isDesktopOnly,
  isMobileOnly,
  isTvDevice = false,
  ratio,
}: CarrouselTemplateItemProps): JSX.Element {
  const {
    altImage,
    altLogoChannel,
    endTime,
    isInOffer = true,
    startTime,
    title,
    URLImageOptimizedCompact,
    URLImageOptimizedRegular = '',
    URLLogoChannel = '',
    stickers,
  } = content;
  const onGoing = { startTime, endTime };
  const [isLive, setIsLive] = useState(isOngoing(onGoing));
  const isProgressBarDisplayed = !!(startTime && endTime && isLive && isInOffer);
  const isFeatUpcomingHodorSticker = useSelector(featUpcomingHodorStickerSelector);

  useEffect(() => {
    const timeInterval = setInterval(() => setIsLive(isOngoing(onGoing)), REFRESH_PROGRESS_BAR_MS);
    return () => clearInterval(timeInterval);
  }, [startTime, endTime]); // eslint-disable-line react-hooks/exhaustive-deps

  const hodorSticker = getHodorSticker(stickers, isFeatUpcomingHodorSticker);
  const showStickerLiveLabel = isLive && isInOffer;
  const showSticker = !!hodorSticker || (!!startTime && !!endTime) || showStickerLiveLabel;

  return (
    <>
      <CoverImage
        dimensions={DIMENSIONS.CARROUSEL[ratio]}
        borderRadius={borderRadius}
        image={{ default: URLImageOptimizedRegular, mobile: URLImageOptimizedCompact }}
        onClickData={content}
        title={altImage || title}
        isDesktopOnly={isDesktopOnly}
        isMobileOnly={isMobileOnly}
        isTvDevice={isTvDevice}
        isArtDirection
      >
        <>
          {showSticker && (
            <Sticker
              sticker={hodorSticker}
              variant={showStickerLiveLabel ? 'live' : undefined}
              startTime={startTime}
              endTime={endTime}
              className={styles.carrouselTemplate__sticker}
            />
          )}
          {URLLogoChannel ? (
            <CardLogoChannel
              key="logochannel"
              logo={{ default: URLLogoChannel }}
              altImage={altLogoChannel}
              isProgressBarDisplayed={isProgressBarDisplayed}
            />
          ) : null}
        </>
      </CoverImage>
      {isProgressBarDisplayed && (
        <div className={styles.carrouselTemplate__progressBarPositioner}>
          <ProgressBar startTime={startTime} endTime={endTime} />
        </div>
      )}
    </>
  );
}

export default memo(CarrouselTemplateItem);
